<template>
  <div>
    <div class="label">{{ textFloat }}</div>
    <div class="d-flex align-items-end">
      <div v-if="value" :class="className">
        <img v-if="isImage" :class="className" :src="file" alt="your image" />
        <div
          v-else
          class="image-preview"
          :style="{
            'background-image': 'url(' + value + ')',
          }"
        ></div>
        <div class="delete-image">
          <div class="btn-close zoom-shrink" @click="deleteImage()">
            <div class="close_icon zoom-shrink"></div>
          </div>
        </div>
      </div>
      <div v-else :class="className" @click="addImageUpload()">
        <div class="plus">+</div>
      </div>
      <b-button
        v-if="value"
        class="btn-image-upload"
        variant="none"
        @click="addImageUpload()"
      >
        <font-awesome-icon
          icon="file-upload"
          color="white"
          class="bg-icon mr-2"
        />
        <span class="mx-auto"> Attach file</span>
      </b-button>
      <input
        accept="image/png, image/jpeg"
        type="file"
        :ref="name"
        hidden
        @input="handleImageUpload($event, $event.target.value)"
        @change="onImageChange($event)"
      />
    </div>

    <div class="mt-2">
      <small>{{ desc }}</small>
    </div>
  </div>
</template>
<script>
export default {
  // props: ["textFloat", "desc", "value", "name", "imagePath",],
  props: {
    textFloat: { required: false },
    desc: { required: false },
    value: { required: false },
    name: { required: false },

    imagePath: { required: false },
    className: { required: false, default: "image-upload-preview" },
  },
  data() {
    return {
      file: this.value,
      isImage: false,
    };
  },
  mounted() {},
  methods: {
    deleteImage() {
      this.file = "";
      this.$emit("deleteImage", this.name);
    },
    addImageUpload() {
      this.$refs[this.name].click();
    },
    async handleImageUpload(evt, value) {
      if (
        evt.target.files[0].type != "image/png" &&
        evt.target.files[0].type != "image/jpeg"
      ) {
        return this.$swal2.fire(
          "เกิดข้อผิดพลาด",
          "กรุณาอัพโหลดเฉพาะไฟล์ .PNG",
          "error"
        );
      }
      if (evt.target.files[0].size / 1024 / 1024 > 1) {
        return this.$swal2.fire(
          "เกิดข้อผิดพลาด",
          "กรุณาอัพโหลดขนาดไม่เกิน 1 MB",
          "error"
        );
      }
      const imageToBase64 = await this.encodeImageFileAsURL(evt.target);
      var that = this;
      imageToBase64.onload = async function (e) {
        // const value = e.target.result.replace("data:image/png;base64,", "");

        const value = await that.setUpdataImage(imageToBase64.result);
        that.$emit("input", value);
      };
    },
    encodeImageFileAsURL(element) {
      return new Promise((resolve) => {
        var file = element.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        resolve(reader);
      });
    },
    onImageChange(evt) {
      this.isImage = true;
      const file = evt.target.files;
      if (file && file.length > 0) {
        this.file = window.URL.createObjectURL(file[0]);
      }
    },
    async setUpdataImage(base64) {
      var url = await this.saveImagetoDb(base64, false);
      return url;
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
  },
};
</script>
<style lang="scss">
.btn-image-upload {
  color: #fff;
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  min-width: 120px;
  margin-left: 5px;
}
.label {
  color: #092d53;
  font-size: 16px;
  margin-bottom: 0.5rem;
  font-weight: bold;
}
small {
  color: #929293;
}
.image-preview {
  height: 100%;
  width: 100%;
  object-fit: contain;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  align-items: center;

  position: relative;
}

.btn-close-image-preview {
  position: relative;
}
.image-upload-preview {
  height: 150px;
  width: 150px;
  display: flex;
  justify-content: center;
  position: relative;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
}
.plus {
  font-size: 32px;
  font-weight: bold;
  color: var(--primary-color);
}
.delete-image {
  top: 2px;
  position: absolute;
  right: 5px;
}
.btn-close {
  position: relative;
  width: 2em;
  height: 2em;
}
.btn-close:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 0;
  width: 0;
  border: 1em solid rgba(100, 100, 100, 0.8);
  border-radius: 6em;
  transition: all 0.2s ease-in-out;
}
.close_icon {
  transition: all 0.075s ease-in-out;
  position: absolute;
  width: 2em;
  height: 2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 0.1em solid;
  border-color: rgba(250, 250, 250, 0.8);
  border-radius: 6em;
  cursor: pointer;
}
.close_icon:after {
  transition: all 0.075s ease-in-out;
  font: 1em Helvetica, arial;
  font-size: 1em;
  width: 100%;
  height: 100%;
  content: "x";
  color: rgba(250, 250, 250, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0, -5%) scale(1.25, 1);
}

.btn-close.zoom-shrink:hover .close_icon.zoom-shrink {
  font-size: 1.2em;
}

.btn-close.zoom-shrink:active .close_icon.zoom-shrink:after {
  font-size: 0.75rem;
  color: rgba(50, 50, 50, 0.8);
}
.container-image-preview {
  position: relative;
}
</style>
